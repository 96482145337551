import { useContext } from '@nuxtjs/composition-api';
import { languageMap } from '~/enums/languagesEnum';

const MARKET_GRN = 'grn:channelmanager:market::65844569ea12e52585591fe3';

export const useGeminiConfiguration = () => {
  const {
    app,
    $config,
    route: { path },
  } = useContext();

  const loadConfiguration = () => {
    const currentLocale = path?.split('/')?.[1] || 'it';
    const state = app?.$vsf?.$gemini?.config?.state;
    const market = $config?.marketGrn || MARKET_GRN; // fallback to hardcoded market
    if (state) {
      state.setMarket(market);
      state.setAcceptLanguage(languageMap[currentLocale]);
      state.setCurrency('EUR');
      state.setLocale(currentLocale);
    }
  };

  return {
    loadConfiguration,
  };
};
