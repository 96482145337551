import type { ProductMediaGalleryAsset } from '@gemini-vsf/api-client/lib/types/GraphQL';

export const mediaGalleryHelpers = () => {
  const filterAndGenerateSrcSet = (gallery?: ProductMediaGalleryAsset[], filterBy?: string) => {
    if (!gallery || !filterBy) return [];
    const widths = [
      [320, 320],
      [480, 480],
      [768, 768],
      [1024, 369],
    ];
    return gallery
      .filter((m) => m.roles.includes(filterBy))
      .map((m) => {
        const srcset = widths.map((width: number[]) => `${m.url}?f=a&w=${width[0]} ${width[1]}w`).join(', ');
        return {
          ...m,
          srcset,
        };
      });
  };

  const generateVideoData = (gallery?: ProductMediaGalleryAsset[]) => {
    const video = gallery.find((m) => m.roles.includes('media_gallery') && m.video_content);
    if (video) {
      try {
        return {
          ...video,
          videoId: video.video_content.video_url.match(/vimeo\.com\/(?:channels\/(?:\w+\/)?|video\/)?(\d+)/i)[1],
        };
      } catch (error) {
        console.warn('generateMediaGallery ~ error while parsing vimeo video url:', error);
      }
    }
    return null;
  };

  return {
    filterAndGenerateSrcSet,
    generateVideoData,
  };
};
