/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref, useContext, computed } from '@nuxtjs/composition-api';
import axios from 'axios';
import { isClient } from '~/helpers/check-environment';
import { useGeminiApi } from '~/composables/';
import { sharedRef } from '@vue-storefront/core';

const NUXT_CMS_ENDPOINT = 'gemini/getCmsPage';

const useCms = (key = 'common') => {
  const cmsData = ref(null) as Record<string, unknown>;
  const {
    route: {
      value: { path },
    },
    app: {
      $config: { ssrMiddlewareUrl, middlewareUrl },
      i18n: {
        localeProperties: { code: localeCode },
      },
      localePath,
    },
  } = useContext();
  const currentLocale = computed(() => {
    try {
      return path?.split('/')?.[1] || 'it';
    } catch (error) {
      console.error('useCms ~ currentLocale ~ error:', error);
    }
    return 'it';
  });
  const privacyPolicyUrl = computed(() => {
    try {
      return localePath(`/privacy-policy-${currentLocale.value}`);
    } catch (error) {
      console.error('useCms ~ privacyPolicyUrl ~ error:', error);
    }
    return '/it/privacy-policy-it';
  });

  const getCmsPage = async (entityId: string, locale = '') => {
    try {
      const { data } = await axios.get(`${isClient ? middlewareUrl : ssrMiddlewareUrl}${NUXT_CMS_ENDPOINT}`, {
        params: {
          entityId,
          locale: locale || localeCode,
        },
      });
      cmsData.value = data;
    } catch (e) {
      console.error(e);
    }
  };

  const componentsData = sharedRef<object>({}, key);
  const componentsMap = {
    prefooter: '66213b0f43e79380929c7a52',
  };
  const { getPayloadData } = useGeminiApi();
  const getComponent = async (component: string): Promise<object | null> => {
    try {
      if (!componentsMap[component]) {
        return null;
      }
      if (componentsData.value[component]) {
        return componentsData.value[component];
      }
      const payloadData = await getPayloadData('components', `${componentsMap[component]}`);
      componentsData.value = {
        ...componentsData.value,
        [component]: payloadData,
      };
      return payloadData;
    } catch (e) {
      console.error('Unable to fetch components.', e);
      return null;
    }
  };

  return {
    cmsData,
    getCmsPage,
    getComponent,
    privacyPolicyUrl,
  };
};

export default useCms;
