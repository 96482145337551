/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref, useContext } from '@nuxtjs/composition-api';
import axios from 'axios';
import { InternalEndpoints } from '~/enums/internalEndpoints';
import { isClient } from '~/helpers/check-environment';
import { getListUrlRewritesByTargetPaths } from '~/serverMiddleware/getGeminiData';

const parseResponse = (response: Record<string, unknown>) => {
  const { firstname, lastname } = JSON.parse(response.recipients[0]);
  return {
    firstname,
    lastname,
    street: response.addressLines,
    city: response.locality,
    postcode: response.postalCode,
    region: {
      region: response.administrativeArea,
      region_code: response.administrativeArea,
    },
    country_code: response.regionCode,
    telephone: response.phoneNumber,
  };
};

const useGeminiApi = () => {
  const geminiData = ref(null) as Record<string, unknown>;
  const loading = ref(false);
  const error = ref(null) as Record<string, unknown>;
  const {
    app: {
      $config: { ssrMiddlewareUrl, middlewareUrl },
      i18n: {
        localeProperties: { code: localeCode },
      },
    },
  } = useContext();

  const getAdditionalOrderData = async (orderId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data, status } = await axios.get(InternalEndpoints.GetAdditionalOrderData, {
      params: {
        orderId,
      },
    });
    if (status === 200) {
      try {
        geminiData.value = {
          shipping: data.shippingAddress ? parseResponse(data.shippingAddress) : null,
          billing: data.billingAddress ? parseResponse(data.billingAddress) : null,
        };
      } catch (e) {
        error.value = e;
      }
      return;
    }
    error.value = data;
  };

  const getPayloadData = async (collectionName: string, id: string, locale = '') => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.GetPayloadData,
        {
          collectionName,
          id,
          locale: locale || localeCode,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (getPayloadDataError) {
      console.error('getPayloadData ~ error:', getPayloadDataError);
    }
    return null;
  };

  const findPayloadData = async (collectionName: string, query: Record<string, unknown>, locale: string) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.FindPayloadData,
        {
          collectionName,
          query,
          locale,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (findPayloadDataError) {
      console.error('findPayloadData ~ error:', findPayloadDataError);
    }
    return null;
  };

  const getGeminiData = async (entityGrn: string) => {
    loading.value = true;
    geminiData.value = null;
    error.value = null;
    try {
      if (!process.client) {
        geminiData.value = await getListUrlRewritesByTargetPaths(entityGrn);
      } else {
        const response = await axios.get('/api/getgeminidata', {
          params: {
            grn: entityGrn,
            time: Date.now(),
          },
        });
        geminiData.value = response.data;
      }
    } catch (err) {
      error.value = err;
    }
    loading.value = false;
  };

  const validateRecaptcha = async (token: string) => {
    try {
      const {
        data: { success },
        status,
      } = await axios.post(InternalEndpoints.ValidateRecaptcha, {
        token,
      });
      return status === 200 && success;
    } catch (e) {
      console.error('validateRecaptcha ~ error:', e);
    }
    return null;
  };

  return {
    geminiData,
    loading,
    error,
    getAdditionalOrderData,
    getPayloadData,
    validateRecaptcha,
    findPayloadData,
    getGeminiData,
  };
};

export default useGeminiApi;
