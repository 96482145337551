/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useContext, useRoute } from '@nuxtjs/composition-api';
import {Cart, cartGetters, CartItem, orderGetters, productGetters, userGetters} from '@gemini-vsf/composables';
import { Config, Customer, Product, Order } from '@gemini-vsf/api-client';
import { AdditionalData, GtmCategories } from '~/types/types';

interface LocalConfig extends Config {
  geminiBaseUrl: string;
}

const useGtm = () => {
  const {
    app: {
      $gtm,
      $vsf: {
        $gemini: { config },
      },
    },
    $config: {
      gtm: gtmConfig
    },
  } = useContext();
  const { geminiBaseUrl } = config as LocalConfig;
  const route = useRoute();
  const { fullPath } = route.value;

  const initGtm = () => {
    if (gtmConfig?.enabled) {
      $gtm.init(gtmConfig.id)
    }
  };

  const addGtmListener = () => {
    // init gtm on page load and after waiting for 5 seconds
    if (process.client) {
      window.addEventListener('load', () => {
        const timer = setTimeout(initGtm, 5000);
        const trigger = () => {
          initGtm();
          clearTimeout(timer);
        };
        const events = ['mouseover', 'keydown', 'touchmove', 'touchstart'];
        events.forEach((e) => window.addEventListener(e, trigger, { passive: true, once: true }));
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gtmPush = (data: any) => {
    // @ts-ignore
    if (typeof window === 'undefined' || !window.Cookiebot) return;
    try {
      // @ts-ignore
      const { Cookiebot: { consent = {} } } = window;
      const hasAllConsents = (consent && (consent.marketing && consent.preferences && consent.statistics)) || false
      if (hasAllConsents) $gtm.push(data)
    } catch (error) {
      console.error('error while parsing consents data', error)
    }
  }

  const getCategoryForGtm = (product: Product, returnType = 'url'): string => {
    const sortedCategoriesForGtm: GtmCategories = product?.categories
      ?.map((c) => ({
        urlArray: c.url_path?.split('/'),
        name: c.name,
        url: c.url_path,
        uid: c.uid,
      }))
      .reduce((a, b) => (a?.url?.length > b?.url?.length ? a : b));
    let returnValue = '';
    switch (returnType) {
      case 'uid': {
        returnValue = sortedCategoriesForGtm?.uid || '';
        break;
      }
      case 'name': {
        returnValue = sortedCategoriesForGtm?.name || '';
        break;
      }
      default: {
        returnValue =
          sortedCategoriesForGtm?.urlArray?.length > 1
            ? sortedCategoriesForGtm.urlArray[1]
            : sortedCategoriesForGtm?.urlArray?.length > 0
              ? sortedCategoriesForGtm.urlArray[0]
              : '';
      }
    }
    return returnValue;
  };

  const getProductDataFromCartForGtm = (product: CartItem) =>
    product.product.sku.includes('/')
      ? {
        item_name: cartGetters.getItemName(product) || '',
        item_id: cartGetters.getItemSku(product) || '',
        price: cartGetters.getItemPrice(product).special || cartGetters.getItemPrice(product).regular || '',
        item_brand: 'Tre Ponti',
        item_category: getCategoryForGtm(product.product),
        item_variant: product?.product?.configurable_product_options_selection?.variant?.merchant_sku || '',
        quantity: cartGetters.getItemQty(product),
      }
      : {
        item_name: cartGetters.getItemName(product) || '',
        item_id: cartGetters.getItemSku(product) || '',
        price: cartGetters.getItemPrice(product).special || cartGetters.getItemPrice(product).regular || '',
        item_brand: 'Tre Ponti',
        item_category: getCategoryForGtm(product.product),
        quantity: cartGetters.getItemQty(product),
      };

  const addToCartPush = (product: Product, additionalData?: AdditionalData) => {
    const currency = additionalData?.currencyCode || product?.price_range?.minimum_price?.final_price.currency || 'EUR';
    const quantity = additionalData.quantity || 1;
    const productData =
      product?.vsf_typename === 'ConfigurableProduct'
        ? {
          item_name: product?.name || '',
          item_id: product?.merchant_sku || '',
          price: productGetters.getPrice(product).special || productGetters.getPrice(product).regular || '',
          item_brand: 'Tre Ponti',
          item_category: getCategoryForGtm(product),
          item_variant: product?.configurable_product_options_selection?.variant?.merchant_sku || '',
          quantity,
        }
        : {
          item_name: product?.name || '',
          item_id: product?.merchant_sku || '',
          price: productGetters.getPrice(product).special || productGetters.getPrice(product).regular || '',
          item_brand: 'Tre Ponti',
          item_category: getCategoryForGtm(product),
          quantity,
        };
    const filteredAdditionalData = { ...additionalData };
    if (filteredAdditionalData.currencyCode) delete filteredAdditionalData.currencyCode;
    gtmPush({
      event: 'add_to_cart',
      ecommerce: {
        currency,
        value: (productGetters.getPrice(product).special || productGetters.getPrice(product).regular) * (quantity) || '',
        items: [
          {
            ...productData,
          }
        ],
      },
    });
  };

  const removeFromCartPush = (product: CartItem) => {
    gtmPush({
      event: 'remove_from_cart',
      ecommerce: {
        currency: 'EUR',
        value: (cartGetters.getItemPrice(product).special ?? cartGetters.getItemPrice(product).regular) * cartGetters.getItemQty(product) || '',
        items: [
          {
            ...getProductDataFromCartForGtm(product),
          }
        ],
      },
    });
  };

  // const checkoutPush = (step: string, option: string, products: TrePontiCartItem[]) => {
  //   const productsData = [];
  //   products?.forEach((p) => {
  //     productsData.push(getProductDataFromCartForGtm(p));
  //   });
  //   productsData.filter(Boolean);
  //   gtmPush({
  //     event: 'checkout',
  //     ecommerce: {
  //       checkout: {
  //         actionField: { step, option },
  //         products: productsData,
  //       },
  //     },
  //   });
  // };

  const purchasePush = (
    order: Order,
    user: Customer,
  ) => {
    const productsData = order?.items?.map((p) => {
      return p.product.vsf_typename === 'ConfigurableProduct'
        ? {
          item_uid: orderGetters.getItemSku(p) || p?.product?.sku || '',
          item_name: orderGetters.getItemName(p) || p?.product?.name || '',
          price: p?.product?.configurable_product_options_selection?.variant?.price_range?.minimum_price?.final_price?.value || orderGetters.getItemPrice(p) ||'',
          item_brand: 'Tre Ponti',
          item_variant: p?.product?.configurable_product_options_selection?.variant?.merchant_sku || '',
          quantity: p?.qtyOrdered || 1,
        }
        : {
          item_uid: orderGetters.getItemSku(p) || p?.product?.sku || '',
          item_name: orderGetters.getItemName(p) || p?.product?.name || '',
          price: orderGetters.getItemPrice(p) || p?.product?.price_range?.minimum_price?.final_price.value || '',
          item_brand: 'Tre Ponti',
          quantity: p?.qtyOrdered || 1,
        };
    });
    gtmPush({
      event: 'purchase',
      ecommerce: {
        currency: 'EUR',
        value: order.total,
        items: productsData,
        transaction_id: order.order_number,
        PageType: 'thank-you',
        ...(user && {
          email: user.email || '',
          address: {
            'first_name': user.firstname || '',
            'last_name': user.lastname || '',
          }
        })
      },
    });
  };

  const loginPush = (user: Customer) => {
    if (user) {
      gtmPush({
        customerLoggedIn: 1,
        firstname: userGetters.getFirstName(user),
        lastname: userGetters.getLastName(user),
        email: userGetters.getEmailAddress(user),
      });
    }
  };

  const logoutPush = (user: Customer) => {
    if (user) {
      gtmPush({
        customerLoggedIn: 0,
        firstname: userGetters.getFirstName(user),
        lastname: userGetters.getLastName(user),
        email: userGetters.getEmailAddress(user),
      });
    }
  };

  const checkoutStepPush = (step: number, params: { option?: string; description: string }) => {
    gtmPush({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'EUR',
        checkout: {
          actionField: {
            step,
            description: params?.description,
            action: 'checkout',
          },
        },
      },
    });
    if (params?.option) {
      gtmPush({
        event: 'checkoutOption',
        ecommerce: {
          checkout_option: {
            actionField: {
              step,
              option: params?.option,
              action: 'checkout_option',
            },
          },
        },
      });
    }
  };

  const cartPush = (cart: Cart, cartItems: CartItem[]) => {
    const cartProductItems = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    cartItems.forEach((cartItem) => cartProductItems.push({...getProductDataFromCartForGtm(cartItem),affiliation: geminiBaseUrl,}))
    if (cart) {
      gtmPush({
        ecommerce: {
          currency: cart?.prices?.grand_total?.currency || 'EUR',
          value: cart?.prices?.grand_total?.value,
          items: [
            ...cartProductItems,
          ],
        },
        event: 'view_cart',
      });
    }
  };

  const categoryPush = (products: Product[], catData: { name: string; uid: string }) => {
    if (products?.length === 0) return;
    const productList = products.map((p, idx) => {
      return p?.vsf_typename === 'ConfigurableProduct'
        ? {
            item_id: p?.merchant_sku || '',
            item_name: p?.name,
            price: productGetters.getPrice(p).special || productGetters.getPrice(p).regular || '',
            item_category: getCategoryForGtm(p),
            item_brand: 'Tre Ponti',
        }
        : {
            item_id: p?.merchant_sku || '',
            item_name: p?.name,
            price: productGetters.getPrice(p).special || productGetters.getPrice(p).regular || '',
            item_category: getCategoryForGtm(p),
            item_brand: 'Tre Ponti',
        };
    });
    gtmPush({
      ecommerce: {
        items: {
          ...productList,
        },
      },
      PageType: 'product-list',
      event: 'view_item_list',
    });
  };

  const productPush = (product: Product) => {
    const gtmProduct =
      product?.vsf_typename === 'ConfigurableProduct'
        ? {
          item_id: product?.merchant_sku || '',
          item_name: product?.name || '',
          price: productGetters.getPrice(product).special || productGetters.getPrice(product).regular || '',
          item_category: getCategoryForGtm(product),
          item_brand: 'Tre Ponti',
        }
        : {
          item_id: product?.merchant_sku || '',
          item_name: product?.name || '',
          price: productGetters.getPrice(product).special || productGetters.getPrice(product).regular || '',
          item_category: getCategoryForGtm(product),
          item_brand: 'Tre Ponti',
        };
    gtmPush({
      ecommerce: {
        items: [
          {
            ...gtmProduct
          }
        ],
        PageType: 'product-page',
      },
      event: 'view_item',
    });
  };

  const pageViewPush = (pageType?: string) => {
    gtmPush({
      event: 'PageView',
      content_name: pageType || '',
      content_url: `${geminiBaseUrl}${fullPath}` || '',
    });
  };

  const addToWishlistPush = (wishlistItem) => {
    const gtmProduct =
      wishlistItem?.vsf_typename === 'ConfigurableProduct'
        ? {
          item_id: wishlistItem?.uid || '',
          item_name: wishlistItem?.name || '',
          price: wishlistItem?.configurable_product_options_selection?.variant?.price_range?.minimum_price?.final_price?.value || '',
          item_brand: wishlistItem?.marchio_attribute?.label || '',
          sku: wishlistItem?.merchant_sku || '',
        }
        : {
          item_id: wishlistItem?.uid || '',
          item_name: wishlistItem?.name || '',
          price: wishlistItem?.price_range?.minimum_price?.final_price.value || '',
          item_brand: wishlistItem?.marchio_attribute?.label || '',
          sku: wishlistItem?.merchant_sku || '',
        };
    gtmPush({
      ecommerce: {
        actionField: {
          list: {},
        },
        detail: gtmProduct,
      },
      event: 'add_to_wishlist',
    });
  }

  return {
    purchasePush,
    // checkoutPush,
    addToCartPush,
    getCategoryForGtm,
    removeFromCartPush,
    loginPush,
    checkoutStepPush,
    cartPush,
    categoryPush,
    productPush,
    pageViewPush,
    logoutPush,
    addToWishlistPush,
    addGtmListener,
  };
};

export default useGtm;
