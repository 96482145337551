/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { computed, useContext } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import { useMarkets as useMarketsComposable } from '@gemini-vsf/composables';

const useMarkets = () => {
  const { markets, load: loadMarkets } = useMarketsComposable();
  const {
    app: {
      $i2c: countryIsoToName,
      $config: { marketGrn },
    },
  } = useContext();

  const availableCountries = sharedRef<Record<string, boolean>>({}, 'useMarkets-availableCountries');
  const availableCountriesOptions = sharedRef<Record<string, boolean>>({}, 'useMarkets-availableCountriesOptions');
  const currentMarketCountries = sharedRef<Record<string, boolean>>({}, 'useMarkets-currentMarketCountries');
  const currentMarketCountriesOptions = sharedRef<Record<string, boolean>>({}, 'useMarkets-currentMarketCountriesOptions');

  const currentMarket = computed(() => markets.value?.find((market) => market.grn === marketGrn));

  const loadAvailableCountries = async () => {
    if (markets.value.length === 0) await loadMarkets();
    if (!markets.value || markets.value.length === 0) {
      availableCountries.value = [];
      return;
    }
    availableCountries.value = markets.value.reduce((accumulator, market) => [...accumulator, ...market.countries], []);
    availableCountries.value.sort((a, b) => a.localeCompare(b));
  };

  const loadAvailableCountriesOptions = async () => {
    if (!availableCountries.value || !Array.isArray(availableCountries.value)) await loadAvailableCountries();
    if (availableCountries.value.length === 0) {
      availableCountriesOptions.value = [];
      return;
    }
    availableCountriesOptions.value = availableCountries.value.map((isoCode: string) => ({
      value: isoCode,
      label: countryIsoToName(isoCode) || isoCode,
    }));
    availableCountriesOptions.value.sort((a, b) => a.label.localeCompare(b.label));
  };

  const loadCurrentMarketCountries = async () => {
    if (markets.value.length === 0) await loadMarkets();
    if (!markets.value || markets.value.length === 0) {
      currentMarketCountries.value = [];
      return;
    }
    currentMarketCountries.value = currentMarket.value?.countries || [];
    currentMarketCountries.value.sort((a, b) => a.localeCompare(b));
  };

  const loadCurrentMarketCountriesOptions = async () => {
    if (!currentMarketCountries.value || !Array.isArray(currentMarketCountries.value)) await loadCurrentMarketCountries();
    if (currentMarketCountries.value.length === 0) {
      currentMarketCountriesOptions.value = [];
      return;
    }
    currentMarketCountriesOptions.value = currentMarketCountries.value.map((isoCode: string) => ({
      value: isoCode,
      label: countryIsoToName(isoCode) || isoCode,
    }));
    currentMarketCountriesOptions.value.sort((a, b) => a.label.localeCompare(b.label));
  };

  return {
    markets,
    load: loadMarkets,
    currentMarket,
    availableCountries,
    loadAvailableCountries,
    currentMarketCountries,
    loadCurrentMarketCountries,
    availableCountriesOptions,
    loadAvailableCountriesOptions,
    currentMarketCountriesOptions,
    loadCurrentMarketCountriesOptions,
  };
};

export default useMarkets;
