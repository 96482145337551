/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { checkoutShipping as checkoutShippingComposable } from '~/composables/useCheckout/checkoutShipping';
import { checkoutState } from '~/composables/useCheckout/checkoutState';
import { setItem } from '~/helpers/asyncLocalStorage';
import { CheckoutStep } from '~/types/enums';
import { addressFromApiToForm, addressFormToApi } from '~/helpers/checkout/address';
import dataManipulation from '~/helpers/dataManipulation';
import { checkoutAdditionalDataToCartNotes } from '~/composables/useCheckout/_helpers';

const { findErrorInResponse } = dataManipulation();

export const checkoutBilling = (cartId: string) => {
  const checkout = checkoutState(cartId);
  const checkoutShipping = checkoutShippingComposable(cartId);
  /**
   * Sets the given address as the checkout billing address.
   * @param address - The address to set as the checkout billing address.
   * @param tryDefault - Whether to try to use the default billing address if the given address is null or undefined.
   */
  const setCheckoutBilling = async (address: any, tryDefault = false) => {
    if (tryDefault) {
      const defaultAddress = address.find((a: any) => a.default_billing);
      if (defaultAddress) {
        checkout.checkoutBilling.value = defaultAddress;
        checkout.checkoutBillingAddressId.value = defaultAddress.id;
        await setItem(CheckoutStep.Billing, defaultAddress);
      }
      return;
    }
    checkout.checkoutBilling.value = address;
    checkout.checkoutBillingAddressId.value = address.id;
    await setItem(CheckoutStep.Billing, address);
  };

  /**
   * Sets the billing address on the cart.
   * @param isNewAddress - Whether the billing address is a new address or an existing one.
   * @param useShippingAddress - Whether to use the shipping address as the billing address.
   */
  const setBillingAddressOnCart = async (isNewAddress = false, useShippingAddress = false) => {
    checkout.stepLoading.value = true;
    try {
      const customerAddressId = checkout.checkoutBilling?.value?.id;
      // eslint-disable-next-line no-underscore-dangle
      const shippingAddressFromForm = typeof checkout.checkoutShipping?.value?.region !== 'string' && !checkout.checkoutShipping?.value?.__typename;
      const shippingAddress = shippingAddressFromForm
        ? addressFormToApi(checkout.checkoutShipping.value)
        : addressFromApiToForm(checkout.checkoutShipping.value);
      const addressToConsider = useShippingAddress ? shippingAddress : addressFromApiToForm(checkout.checkoutBilling.value);
      await setItem(CheckoutStep.Billing, addressToConsider);
      await checkout.setBillingAddressOnCartComposable({
        params: {},
        billingDetails: {
          ...(isNewAddress ? addressFormToApi(checkout.checkoutBilling.value) : addressToConsider),
          customerAddressId,
          sameAsShipping: useShippingAddress,
        },
        customQuery: {
          setBillingAddressOnCart: 'setBillingAddressOnCartCustom',
        },
      });
      await checkoutShipping.setShippingMethodOnCart(checkout.checkoutShippingMethod.value, false);
      const setError = findErrorInResponse(checkout.billingErrors.value);
      if (setError) {
        checkout.notifyCheckoutError(CheckoutStep.Billing, setError);
        checkout.stepLoading.value = false;
        return;
      }
      const additionalDataCartNotes = checkoutAdditionalDataToCartNotes(checkout.checkoutAdditionalData.value);
      if (additionalDataCartNotes.length > 0) {
        await checkout.setAdditionalNoteData(additionalDataCartNotes);
      }
      await checkout.loadCart();
      checkout.stepLoading.value = false;
      await checkout.router.push(`/${checkout.locale}/checkout/${CheckoutStep.Payment}`);
    } catch (error) {
      console.error(error);
    }
    checkout.stepLoading.value = false;
  };
  return {
    setCheckoutBilling,
    setBillingAddressOnCart,
  };
};
