/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { computed, ref } from '@nuxtjs/composition-api';
import seoHelpers from '~/helpers/seo/seoHelpers';
import { useGeminiApi } from '~/composables/';

const sanificateIncomingData = (obj) => {
  if (typeof obj !== 'object' || obj === null) return obj;

  return Object.entries(obj).reduce(
    (newObj, [key, value]) => {
      if (['id', 'blockName', 'blockType'].includes(key)) {
        return newObj; // skip these keys
      }
      const newKey = key === 'type' ? '@type' : key;
      newObj[newKey] = sanificateIncomingData(value);
      return newObj;
    },
    Array.isArray(obj) ? [] : {}
  );
};

const useHome = () => {
  const { getAlternates, getCanonical } = seoHelpers();
  const { getPayloadData } = useGeminiApi();
  const homeMeta = ref({});
  const alternates = ref([]);

  const homePageId = '661fd716f054c6bab40833b2';
  const homeOrganizationDataId = '66276dcc0f7747afad64cb97';
  const homeSiteLinkDataId = '66276e190f7747afad64cbb6';

  const structuredOrganizationData = ref();
  const structuredSiteLinkData = ref();
  const getHomeAlternates = async () => {
    try {
      alternates.value = await getAlternates('home', true);
    } catch (e) {
      console.error('Unable to get alternates', e);
    }
  };

  const populateHomeMeta = async () => {
    await getHomeAlternates();
    homeMeta.value = {
      title: 'Homepage - Tre Ponti',
      meta: [
        {
          hid: 'og:description',
          property: 'og:description',
          content: `Tre Ponti | Pettorine d'eccellenza per cani di piccola e grossa taglia`,
        },
        {
          hid: 'description',
          name: 'description',
          content: `Tre Ponti | Pettorine d'eccellenza per cani di piccola e grossa taglia`,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'TrePonti',
        },
        {
          name: 'trustpilot-one-time-domain-verification-id',
          content: '7b6f4c4e-c2db-4ede-9ef6-7c63058b20d8',
        },
      ],
      link: [...alternates.value, getCanonical()],
      script: [
        {
          json: {
            '@context': 'http://schema.org',
            ...structuredOrganizationData.value,
          },
          type: 'application/ld+json',
        },
        {
          json: {
            '@context': 'http://schema.org',
            ...structuredSiteLinkData.value,
          },
          type: 'application/ld+json',
        },
      ],
    };
  };

  const homeBlocks = ref();
  const loadHomeBlocks = async () => {
    try {
      homeBlocks.value = await getPayloadData('pages', homePageId);
    } catch (e) {
      console.error('Unable to fetch home content.', e);
    }
  };

  const loadStructuredData = async () => {
    try {
      const organizationData = await getPayloadData('structured-data', homeOrganizationDataId);
      const siteLinkData = await getPayloadData('structured-data', homeSiteLinkDataId);
      structuredOrganizationData.value = sanificateIncomingData(organizationData?.data?.[0]);
      structuredSiteLinkData.value = sanificateIncomingData(siteLinkData?.data?.[0]);
    } catch (error) {
      // Log an error message if the translations block could not be loaded
      console.error(error);
      console.error('Could not get structured data', error);
    }
  };

  return {
    loadHomeBlocks,
    loadStructuredData,
    populateHomeMeta,
    homeBlocks,
    homeMeta,
  };
};

export default useHome;
