/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useProduct, categoryGetters, productGetters } from '@gemini-vsf/composables';
import { sharedRef } from '@vue-storefront/core';
import { useGeminiApi, useWishlist } from '~/composables';
import { useContext, ref } from '@nuxtjs/composition-api';
import type { Product, ProductDetailPageComposable } from '~/types/product';
import seoHelpers from '~/helpers/seo/seoHelpers';
import { mediaGalleryHelpers } from './_helpers';

const useProductDetail = (productId: string): ProductDetailPageComposable => {
  const {
    $config: {
      payloadComponents: { sizeGuide: sizeGuideReferences },
    },
    route: {
      value: { fullPath },
    },
  } = useContext();
  const { findPayloadData } = useGeminiApi();
  const { loadItemsInWishlist, itemsInWishlist } = useWishlist();
  const alternates = ref([]);
  const { getSlug: getCategorySlug } = categoryGetters;
  const { getSlug: getProductSlug } = productGetters;
  const { getAlternates, getCanonical } = seoHelpers();
  const { filterAndGenerateSrcSet, generateVideoData } = mediaGalleryHelpers();

  const product = sharedRef<Product>(null, `useProductDetail-product-${productId}`);
  const productPrices = sharedRef(
    { final: '', regular: null, discount: null, omnibus: null, omnibusDifference: null },
    `useProductDetail-productPrices-${productId}`
  );
  const loading = sharedRef(false, `useProductDetail-loading-${productId}`);
  const productConfiguration = sharedRef([], `useProductDetail-productConfiguration-${productId}`);
  const selectedQuantity = sharedRef(1, `useProductDetail-selectedQuantity-${productId}`);
  const canAddToCart = sharedRef(false, `useProductDetail-canAddToCart-${productId}`);
  const maxAddableQuantity = sharedRef(1, `useProductDetail-maxAddableQuantity-${productId}`);
  const availableConfigurations = sharedRef(null, `useProductDetail-availableConfigurations-${productId}`);
  const metaData = sharedRef(null, `useProductDetail-metaData-${productId}`);
  const mediaGallery = sharedRef(null, `useProductDetail-mediaGallery-${productId}`);
  const mediaGalleryVideo = sharedRef(null, `useProductDetail-mediaGalleryVideo-${productId}`);
  const breadcrumbs = sharedRef(null, `useProductDetail-breadCrumbs-${productId}`);
  const sizeGuide = sharedRef(null, `useProductDetail-sizeGuide-${productId}`);
  const selectedVariant = sharedRef(null, `useProductDetail-selectedVariant-${productId}`);
  const relatedProducts = sharedRef(null, `useProductDetail-relatedProducts-${productId}`);
  const upsellProducts = sharedRef(null, `useProductDetail-upsellProducts-${productId}`);
  const productIsInWishlist = sharedRef(false, `useProductDetail-productIsInWishlist-${productId}`);

  const { products, search } = useProduct(productId);
  const evaluateProductPrices = () => {
    const selectedVariantMinimumPrice = selectedVariant.value?.price_range?.minimum_price;
    const selectedVariantPrice = selectedVariantMinimumPrice?.final_price?.value;
    const configurableMinimumPrice = product?.value?.price_range?.minimum_price;
    const configurablePrice = configurableMinimumPrice?.final_price?.value;
    const prices = (selectedVariantPrice && selectedVariantMinimumPrice) || (configurablePrice && configurableMinimumPrice) || null;
    const omnibusPrice = selectedVariant.value?.omnibus_price || product?.value?.omnibus_price;
    if (!prices || !prices?.final_price?.value) return;
    // calculate the discount percentage based on the regular and final prices only if they differ. round up to closest integer
    const discount =
      prices.regular_price.value !== prices.final_price.value
        ? Math.ceil(((prices.regular_price.value - prices.final_price.value) / prices.regular_price.value) * 100)
        : null;
    // calculate the difference between the omnibus and final prices. round up to closest integer
    const omnibusDifferencePercentage = omnibusPrice ? Math.ceil(((prices.final_price.value - omnibusPrice) / prices.final_price.value) * 100) : null;
    productPrices.value = {
      final: prices.final_price.value,
      regular: prices.regular_price.value !== prices.final_price.value ? prices.regular_price.value : null,
      discount,
      omnibus: omnibusPrice,
      omnibusDifference: omnibusDifferencePercentage,
    };
  };

  const changeQuantity = (quantity: number) => {
    selectedQuantity.value = quantity;
  };

  const reconfigureVariant = (newConfiguration: string[]) => {
    const existingAttribute = productConfiguration.value.find((attribute: string) => attribute.split(':')[0] === newConfiguration[0].split(':')[0]);
    if (existingAttribute) {
      productConfiguration.value = productConfiguration.value.filter(
        (attribute: string) => attribute.split(':')[0] !== newConfiguration[0].split(':')[0]
      );
    }
    if (availableConfigurations.value && !availableConfigurations.value.includes(newConfiguration[0])) {
      productConfiguration.value = newConfiguration;
      return;
    }
    productConfiguration.value = [...new Set([...productConfiguration.value, ...newConfiguration])];
  };

  const updateAvailableConfigurations = () => {
    const options = product?.value?.configurable_product_options_selection?.configurable_options;
    if (options) {
      availableConfigurations.value = options.flatMap((o) => o.values.map((v) => v.uid));
    }
  };

  const evaluateCartAvailability = () => {
    canAddToCart.value = product?.value?.configurable_product_options_selection?.variant?.only_x_left_in_stock > 0;
    maxAddableQuantity.value =
      product?.value?.configurable_product_options_selection?.variant?.only_x_left_in_stock || product?.value?.only_x_left_in_stock || 1;
  };

  const populateMetaData = async () => {
    alternates.value = await getAlternates(`grn:product:product::${productId}`);
    metaData.value = {
      title: product?.value?.meta_title || product?.value?.name || '',
      meta: [
        { hid: 'robots', name: 'robots', content: 'index, follow' },
        {
          hid: 'title',
          name: 'title',
          content: product?.value?.meta_title || product?.value?.name || '',
        },
        {
          hid: 'description',
          name: 'description',
          content: product.value?.meta_description || product?.value?.description?.html || '',
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: product.value?.meta_keywords || product?.value?.name || '',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: product?.value?.meta_title || product?.value?.name || '',
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: product.value?.meta_description || product?.value?.description?.html || '',
        },
      ],
      link: [...alternates.value, getCanonical()],
    };
  };

  const generateMediaGallery = () => {
    mediaGallery.value = [
      ...filterAndGenerateSrcSet(product?.value?.configurable_product_options_selection?.media_gallery, 'only_variant'),
      ...filterAndGenerateSrcSet(product?.value?.media_gallery, 'media_gallery'),
    ];
    mediaGalleryVideo.value = generateVideoData(product?.value?.media_gallery);
  };

  const getSizeGuideData = async () => {
    const sizeGuideCode = product?.value?.guida_taglie;
    const currentLocale = fullPath?.split('/')?.[1] || 'it';
    if (!sizeGuideCode) return;
    try {
      const sizeGuideData = await findPayloadData(
        sizeGuideReferences.collection,
        {
          sizeGuideCode: {
            equals: sizeGuideCode,
          },
        },
        currentLocale
      );
      sizeGuide.value = sizeGuideData.docs.find((d) => d.sizeGuideCode === sizeGuideCode);
      if (!sizeGuide.value) return;
      sizeGuide.value.sizesArray = sizeGuide?.value?.sizeGuide?.flatMap((s) => [s.size, s.waist, s.bust]);
    } catch (error) {
      console.warn('an error occurred while fetching the size guide:', error);
    }
  };

  const generateBreadcrumbs = () => {
    let categoryLink;
    let productLink;
    try {
      const categories = product?.value?.categories;
      if (!categories || categories.length === 0) return;
      const categoryWithMostSlashes: { __typename: string; urls: { __typename: string; linkRel: string; urlPath: string }[] } = categories.reduce(
        (prev, current) => {
          const prevCount = prev.urls[0].urlPath?.split('/').length - 1;
          const currentCount = current.urls[0].urlPath?.split('/').length - 1;
          return prevCount > currentCount ? prev : current;
        }
      );
      categoryLink = {
        text: categoryWithMostSlashes.urls[0].urlPath?.trim(),
        link: `${getCategorySlug(categoryWithMostSlashes)}`,
      };
    } catch (error) {
      console.warn('generateBreadcrumbs ~ error while generating product breadcrumbs:', error);
    }

    try {
      productLink = {
        text: product.value.name,
        link: `${getProductSlug(product.value)}`,
      };
    } catch (error) {
      console.warn('generateBreadcrumbs ~ error while generating product breadcrumbs:', error);
    }

    const homeLink = {
      text: 'Home',
      link: '/',
    };

    breadcrumbs.value = [homeLink];

    if (categoryLink) {
      breadcrumbs.value.push(categoryLink);
    }

    if (productLink) {
      breadcrumbs.value.push(productLink);
    }
  };

  const loadProductIsInWishlist = async () => {
    const skuToConsider = selectedVariant?.value?.uid || product?.value?.uid;
    const hasStartedSelectingButNoVariant = product?.value?.configurable_product_options_selection && !selectedVariant?.value?.uid;
    if (!skuToConsider || hasStartedSelectingButNoVariant) return;
    await loadItemsInWishlist([skuToConsider]);
    productIsInWishlist.value = !!itemsInWishlist?.value?.[skuToConsider];
  };

  const load = async () => {
    loading.value = true;
    try {
      await search({
        queryType: 'DETAIL',
        filter: {
          uid: productId,
        },
        customQuery: {
          productDetail: 'productDetailCustom',
        },
      });
      [product.value] = products.value.items;
      relatedProducts.value = product?.value?.related_products?.length > 0 ? product.value.related_products.filter(Boolean) : [];
      upsellProducts.value = product.value?.upsell_products?.length > 0 ? product.value.upsell_products.filter(Boolean) : [];
      evaluateCartAvailability();
      generateMediaGallery();
      generateBreadcrumbs();
      evaluateProductPrices();
      await Promise.all([populateMetaData(), getSizeGuideData(), loadProductIsInWishlist()]);
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  const updateConfiguration = async (newConfiguration: string[] = []) => {
    loading.value = true;
    try {
      reconfigureVariant(newConfiguration);
      await search({
        queryType: 'DETAIL',
        filter: {
          uid: productId,
        },
        configurations: productConfiguration.value,
        customQuery: {
          productDetail: 'productDetailCustomVariant',
        },
      });
      product.value = {
        ...product.value,
        ...products.value.items?.[0],
      };
      selectedVariant.value = product?.value?.configurable_product_options_selection?.variant;
      updateAvailableConfigurations();
      evaluateCartAvailability();
      generateMediaGallery();
      evaluateProductPrices();
      await Promise.all([loadProductIsInWishlist()]);
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  return {
    load,
    product,
    loading,
    productPrices,
    productConfiguration,
    selectedQuantity,
    changeQuantity,
    canAddToCart,
    maxAddableQuantity,
    availableConfigurations,
    metaData,
    mediaGallery,
    mediaGalleryVideo,
    breadcrumbs,
    sizeGuide,
    selectedVariant,
    relatedProducts,
    upsellProducts,
    loadProductIsInWishlist,
    productIsInWishlist,
    updateConfiguration,
  };
};

export default useProductDetail;
