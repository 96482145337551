/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prettier/prettier */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { useCart as useCartComposable } from '@gemini-vsf/composables';
import { computed } from '@nuxtjs/composition-api';
import { useUiState, useUiNotification, useUser, useGtm, useTranslation } from '~/composables';
import { CartNote, UseCart } from '~/types/types';
import { useI18n } from "~/helpers/hooks/usei18n";
import { addNote, parseNotes, getNote } from './_helpers'


const useCart = (): UseCart => {
  const { $gt } = useTranslation('translations');
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    load: loadCart,
    cart,
    addItem: addItemComposable,
    bulkAddItems: bulkAddItemsComposable,
    addNoteOnCart: addNoteOnCartComposable,
    applyCoupon: applyCouponComposable,
    applyGiftCard: applyGiftCardComposable,
    removeCoupon: removeCouponComposable,
    removeGiftCard: removeGiftCardComposable,
    removeItem: removeItemComposable,
    updateItemQty: updateItemQtyComposable,
    isInCart,
    error,
  } = useCartComposable();
  const { toggleCartSidebar } = useUiState();
  const { send: sendNotification } = useUiNotification();
  const trans = useI18n();

  const { isAuthenticated } = useUser();
  const { addToCartPush } = useGtm();

  const cartTotalItems = computed(() => (cart?.value && cart.value?.total_quantity) || null);
  const cartItems = computed(() => (cart?.value && cart.value?.items.filter(Boolean)) || []);
  const cartNotes = computed(() => (cart?.value?.notes && parseNotes(cart.value.notes)) || {});
  const cartId = computed(() => cart?.value?.id);

  const handleEvent = (action = 'load') => {
    const skipActions = [
      'clear',
      'load',
      'removeItem',
      'bulkAddItems',
      'addNoteOnCart',
      'removeNoteFromCartItem',
      'updateItemQty',
      'setItemQuantity',
      'reorder'
    ];

    const errorMessages = {
      addItem: $gt('Error adding item to cart.'),
      removeItem: $gt('Error removing item from cart.'),
      updateItemQty: $gt('Error updating item quantity.'),
      load: $gt('Error loading cart.'),
      clear: $gt('Error clearing cart.'),
      applyCoupon: $gt('Error applying coupon.'),
      removeCoupon: $gt('Error removing coupon.'),
    };

    const errorType = Object.keys(error.value).find(key => error.value[key]);

    if (skipActions.includes(action) && !errorType) return;

    const message = errorType ? errorMessages[errorType] : $gt('Operation successful.');
    const notificationType = errorType ? 'danger' : 'success';
    const icon = errorType ? 'error' : 'check';

    sendNotification({
      id: Symbol(errorType ? 'cart_error' : 'cart_success'),
      message,
      type: notificationType,
      icon,
      persist: !!errorType,
      title: errorType ? $gt('Error') : $gt('Success'),
    });
  };

  const load = async () => {
    await loadCart({
      customQuery: isAuthenticated.value
        ? {
          customerCart: 'customerCartCustom',
        }
        : {
          cart: 'cartCustom',
        },
    });
    handleEvent('load');
  };

  const addItem = async ({ item, quantity }, openSidebar = true) => {
    try {
      await addItemComposable({
        product: item,
        quantity,
        customQuery: {
          addConfigurableProductsToCart: 'addConfigurableProductsToCartCustom',
        },
      });
      addToCartPush(item, { quantity });
      if (cart?.value?.items?.length > 0 && openSidebar) toggleCartSidebar();
    } catch (error) {
      console.error("addItem ~ error:", error);
    }
    handleEvent('addItem');
  };

  const bulkAddItems = async (products: any[], openSidebar = true) => {
    try {
      await bulkAddItemsComposable({
        products,
        customQuery: {
          addProductsToCart: 'addProductsToCartCustom',
        },
      });
      handleEvent('bulkAddItems');
      if (cart?.value?.items?.length > 0 && openSidebar) toggleCartSidebar();
    } catch (error) {
      console.error("bulkAddItems ~ error:", error);
    }
  }

  const addNoteOnCartV2 = async (noteKey: string, note: string) => {
    try {
      const newNote = addNote(noteKey, note, cartNotes.value);
      await addNoteOnCartComposable({
        note: newNote,
        customQuery: { setNotesToCart: 'setNotesToCartCustom' },
      });
      await load();
    } catch (error) {
      console.error("addNoteOnCart ~ error:", error);
    }
    handleEvent('addNoteOnCart');
  };

  const removeNoteFromCartItem = async (merchantSku: string) => {
    try {
      const cartNotesCopy = { ...cartNotes.value };
      delete cartNotesCopy.items?.[merchantSku];
      if (cartNotesCopy.items && Object.keys(cartNotesCopy.items).length === 0) delete cartNotesCopy.items;
      await addNoteOnCartComposable({
        note: JSON.stringify(cartNotesCopy),
        customQuery: { setNotesToCart: 'setNotesToCartCustom' },
      });
    } catch (error) {
      console.error("Could not remove cart note", error);
    }
    handleEvent('removeNoteFromCartItem');
  }

  const applyCoupon = async (couponCode: string) => {
    await applyCouponComposable({
      couponCode,
      customQuery: {
        applyCouponToCart: 'applyCouponToCartCustom',
      },
    })
  };

  const applyGiftCard = async (giftCardCode: string) => {
    await applyGiftCardComposable({
      giftCardCode,
      // customQuery: {
      //   applyGiftCardToCart: 'applyGiftCardToCartCustom',
      // },
    });
  };

  const removeCoupon = async (couponCode: string) => {
    await removeCouponComposable({
      couponCode,
      customQuery: {
        removeCouponFromCart: 'removeCouponFromCartCustom',
      },
    });
  };

  const removeGiftCard = async (giftCardCode: string) => {
    await removeGiftCardComposable({
      giftCardCode,
      // customQuery: { removeGiftCardFromCart: 'removeGiftCardFromCartCustom' },
    });
  };

  const removeItem = async ({ product }) => {
    await removeItemComposable({
      product,
      customQuery: {
        removeItemFromCart: 'removeItemFromCartCustom',
      },
    });
    await removeNoteFromCartItem(product?.product?.merchant_sku);
    handleEvent('removeItem');
  };

  const updateItemQty = async ({ product }, quantity: number) => {
    try {
      await updateItemQtyComposable({
        product,
        quantity,
        customQuery: {
          updateCartItems: 'updateCartItemsCustom',
        },
      });
    } catch (error) {
      console.error("updateItemQty ~ error:", error);
    }
    handleEvent('updateItemQty');
  };

  const setItemQuantity = async ({ product, quantity }) => {
    try {
      await updateItemQtyComposable({
        product,
        quantity,
        customQuery: {
          updateCartItems: 'updateCartItemsCustom',
        },
      });
    } catch (error) {
      console.error("setItemQuantity ~ error:", error);
    }
    handleEvent('setItemQuantity');
  };
  const setAdditionalNoteData = async (additionalData: Array<CartNote>) => {
    if (additionalData) {
      let billingNote = '';
      additionalData.forEach((d) => {
        billingNote += `${trans.t(d.noteKey)}: ${d.note} `;
      });
      await addNoteOnCartComposable({
        note: billingNote,
      });
    }
  }

  return {
    ...useCartComposable(),
    load,
    cart,
    addItem,
    bulkAddItems,
    addNoteOnCartV2,
    setAdditionalNoteData,
    applyCoupon,
    applyGiftCard,
    removeCoupon,
    removeGiftCard,
    removeItem,
    updateItemQty,
    isInCart,
    cartTotalItems,
    cartItems,
    error,
    setItemQuantity,
    cartNotes,
    getNote,
    isAuthenticated,
    cartId,
  };
};

export default useCart;
