import { useApi } from '~/composables/useApi';
import { useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';

const NEWSLETTER_SUBSCRIBE_QUERY = `
  mutation SubscribeEmailToNewsletter($email: String!, $consents: [ConsentInput], $name: String!, $lastName: String!) {
    subscribeEmailToNewsletter(email: $email, consents: $consents, name: $name, lastName: $lastName) {
      status
    }
  }
`;

export const useNewsletter = () => {
  const { query } = useApi();
  const trans = useI18n();
  const { send: sendNotification } = useUiNotification();

  const errorHandler = (error) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    if (error?.extensions?.code?.details?.includes('AlreadyExists')) {
      sendNotification({
        id: Symbol('newsletter_subscription_error'),
        message: `${trans.t('You are already subscribed.')}`,
        type: 'info',
        icon: 'check',
        persist: true,
        title: 'newsletter_subscription_error',
      });
      return;
    }
    sendNotification({
      id: Symbol('newsletter_subscription_error'),
      message: `${trans.t('An error occurred while subscribing to the newsletter. Please try again or contact our customer support.')}`,
      type: 'danger',
      icon: 'check',
      persist: true,
      title: 'newsletter_subscription_error',
    });
  };

  const subscribe = async (email: string) => {
    try {
      const { errors } = await query(NEWSLETTER_SUBSCRIBE_QUERY, {
        email,
        consents: [
          {
            consent_type: 'MARKETING',
            consent_value: true,
          },
          {
            consent_type: 'PRIVACY',
            consent_value: true,
          },
        ],
        name: '',
        lastName: '',
      });
      if (errors?.length > 0) {
        errors.forEach((e) => {
          console.warn('Newsletter subscription error', e.message);
        });
        errorHandler(errors[0]);
        return false;
      }
      sendNotification({
        id: Symbol('newsletter_subscription_success'),
        message: `${trans.t('Subscription successful!')}`,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'newsletter_subscription_success',
      });
      return true;
    } catch (error) {
      sendNotification({
        id: Symbol('newsletter_subscription_error'),
        message: `${trans.t('An unexpected error occurred while subscribing to the newsletter. Please try again or contact our customer support.')}`,
        type: 'danger',
        icon: 'check',
        persist: true,
        title: 'newsletter_subscription_error',
      });
      console.warn('Newsletter subscription error', error);
    }
    return false;
  };

  return {
    subscribe,
  };
};

export default useNewsletter;
