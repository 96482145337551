import type { CartNotes } from '~/types/types';

export const generateNotes = (notes: CartNotes) => JSON.stringify(notes);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const parseNotes = (notes: string): Record<string, string> => JSON.parse(notes);

export const addNote = (noteKey: string, noteValue: string, notes: CartNotes): string => {
  const [key, value] = noteKey === 'cartItem' ? noteValue.split(':') : [noteKey, noteValue];
  if (noteKey === 'cartItem') {
    const cartItemNotes = notes.items || {};
    return generateNotes({ ...notes, items: { ...cartItemNotes, [key]: value } });
  }
  return generateNotes({ ...notes, [key]: value });
};

export const getNote = (noteKey: string, notes: CartNotes, itemKey = ''): any => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return itemKey !== '' ? notes?.[noteKey]?.[itemKey] || '' : notes?.[noteKey] || '';
};

/* const notesExample = {
  items: {
    '24-MB01': 'This is a note for the first item',
    '24-MB04': 'This is a note for the second item',
  },
  orderNotes: 'This is a note for the order',
  orderReference: 'This is a reference note for the order',
  deliveryPreference: 'This is a delivery preference note for the order',
  totalEvasion: 'This is a total evasion note for the order',
  orderType: 'This is a order type note for the order',
}; */
